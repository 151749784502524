import {ReactElement, useEffect, useRef, useState} from "react";
import {IPortfolioFinancialTable, TDataRecord, TTableRow} from "./types";
import {buildDisplayRows, buildRows} from "./logic";
import {FinancialEntityType, useGetPortfolioSnapshotDataLazyQuery} from "../../__generated__/generated_types";
import {useAccountPropetiesDataLoader} from "./accountPropertiesDataLoader";
import {Button} from "@zendeskgarden/react-buttons";
import HotTable from "@handsontable/react";
import {LICENSES} from "../../constants/Licenses";
import {CellCoords} from "handsontable";
import {AccountFilter} from "../../pages/portfolio-financial/components/account-filter/AccountFilter";

export function PortfolioFinancialTable(props: IPortfolioFinancialTable): ReactElement {
    const [fetchPortfolioData, {data: portfolioData, loading: portfolioDataLoading}] = useGetPortfolioSnapshotDataLazyQuery({
        fetchPolicy: "no-cache"
    });

    const [data, setData] = useState<Record<string, TDataRecord>>({});
    const [rows, setRows] = useState<TTableRow[]>();
    const [displayRows, setDisplayRows] = useState<TTableRow[]>();
    const [collapsedRowIds, setCollapsedRowIds] = useState<string[]>();
    const [selectedAccountIds, setSelectedAccountIds] = useState<string[]>();

    const [showAccountFilter, setShowAccountFilter] = useState(false);

    const [hotData, setHotData] = useState<unknown[][]>([]);
    const hotRef = useRef<HotTable>(null);

    const {loadDataForAccount: loadPropertiesValuesForAccount,
        data: accountPropertiesValues,
        reset: resetPropertiesData} =
        useAccountPropetiesDataLoader({snapshotId: props.snapshotId, propertyIds: props.properties.map(p => p.id)});
    function triggerCollapsed(tableRow: TTableRow, collapsed: boolean) {
        if (!collapsed) {
            setCollapsedRowIds(prev =>
                prev?.filter(it => it != tableRow.id)
            );
        }
        else {
            setCollapsedRowIds(prev =>
                [...prev ?? [], tableRow.id]
            );
        }
    }

    function handleHotCellClick(_event: MouseEvent, coords: CellCoords) {
        if (coords.col > 0) {
            return;
        }
        const tableRow = displayRows?.[coords.row];
        if (tableRow && !tableRow.isSubtotalRow && !tableRow.isPropertyRow) {
            triggerCollapsed(tableRow, !collapsedRowIds?.includes(tableRow.id));
        }
    }

    function handleExpandAll() {
        if (!rows) {
            return;
        }
        const ids = new Set<string>();
        for (const row of rows) {
            // As per discussion w/ Briant we expand to GL level and let them (users) expand to Property level when they want
            if (row.type === FinancialEntityType.Account) {
                ids.add(row.id);
            }
        }
        setCollapsedRowIds(Array.from(ids));
    }

    function handleCollapseAll() {
        if (!rows) {
            return;
        }
        const ids = new Set<string>();
        for (const row of rows) {
            ids.add(row.id);
        }
        setCollapsedRowIds(Array.from(ids));
    }

    useEffect(() => {
        fetchPortfolioData({
            variables: {
                snapshotId: props.snapshotId,
                propertyIds: props.properties.map(p => p.id),
            }
        });
        const rows = buildRows(props.chartOfAccountsTree);
        if (!collapsedRowIds) {
            setCollapsedRowIds(Array.from(new Set<string>(rows.map(row => row.id))));
        }
        if (!selectedAccountIds) {
            setSelectedAccountIds(rows.filter(row => !row.isPropertyRow && !row.isSubtotalRow).map(row => row.id));
        }
        setRows(rows);
        resetPropertiesData();
    }, [props.chartOfAccountsTree, props.properties, props.snapshotId]);

    useEffect(() => {
        if (!rows || !selectedAccountIds) {
            return;
        }
        const displayRows = buildDisplayRows(rows, props.properties, collapsedRowIds ?? [], selectedAccountIds);
        setDisplayRows(displayRows);
    }, [rows, collapsedRowIds, selectedAccountIds]);

    useEffect(() => {
        if (!displayRows) {
            return;
        }

        const hotData = [] as string[][];
        for (const row of displayRows) {
            let values: string[] = new Array(24).fill(null);
            let name: string | undefined = undefined;
            if (row.isPropertyRow) {
                name = row.name as string;
                const accountId = row.parentIds[0];
                if (accountId) {
                    const accountPropertiesData = accountPropertiesValues[accountId];
                    if (accountPropertiesData) {
                        values = accountPropertiesData[row.id]?.map(v => v.toString()) ?? new Array(24).fill(null);
                    }
                    else {
                        loadPropertiesValuesForAccount(accountId);
                    }
                }
            }
            else {
                const {name: glName, number: glNumber} = row.name as {name: string, number: string;};
                const dbData = data[row.id];
                name = glName + " " + glNumber;
                if (row.isSubtotalRow) {
                    name = "Total " + name;
                }
                else if (row.type !== FinancialEntityType.Component && !row.isPropertyRow) {
                    name = (collapsedRowIds?.includes(row.id) ? "+ " : "- ") + name;
                }
                if (dbData) {
                    values = dbData.values.map(v => v.toString());
                }
            }
            name = ".".repeat(row.parentIds.length * 3) + name;
            hotData.push([name ?? "", ...values]);
        }
        setHotData(hotData);
    }, [data, displayRows, accountPropertiesValues]);

    useEffect(() => {
        if (!portfolioData || portfolioDataLoading) {
            return;
        }
        const data: Record<string, TDataRecord> = {};
        for (const {id, values} of portfolioData.getPortfolioSnapshotData.accountValues) {
            data[id] = {
                id,
                values: [...values],
            };
        }
        setData(data);
    }, [portfolioData, portfolioDataLoading]);

    return (
        <div style={{width: "100%", height: "calc(100% - 50px)", overflow: "scroll"}}>
            <Button onClick={handleExpandAll}>Expand All</Button>
            <Button onClick={handleCollapseAll}>Collapse All</Button>
            <Button onClick={() => setShowAccountFilter(true)}>Filter Accounts</Button>
            <HotTable
                ref={hotRef}
                data={hotData}
                columnSorting={true}
                settings={{
                    width: "100%",
                    height: "calc(100% - 3rem)",
                    afterOnCellMouseDown: handleHotCellClick,
                    // fixedRowsTop: 1,
                    fixedColumnsLeft: 1,

                    rowHeights: 40,

                    // columns: columnConfig,

                    licenseKey: LICENSES.HandsOnTable
                }}
            />
            {showAccountFilter && selectedAccountIds &&
                <AccountFilter
                    chartOfAccountsTree={props.chartOfAccountsTree}
                    onClose={() => setShowAccountFilter(false)}
                    onConfirm={(ids) => {setSelectedAccountIds(ids); setShowAccountFilter(false);}}
                    selectedAccountIds={selectedAccountIds}
                />
            }
        </div>
    );
}