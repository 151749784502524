import {ReactElement, useEffect, useMemo, useState} from "react";
import {PortfolioFinancialTable} from "../../components/portfolio-financial/PortfolioFinancialTable";
import {useChartOfAccounts} from "../../contexts/chartofaccounts/ChartOfAccountsContext";
import {useProperties} from "../../contexts/properties/PropertiesContext";
import {TSnapshotData} from "./types";
import {Button} from "@zendeskgarden/react-buttons";
import {useGetLatestPortfolioCalcSnapshotInfoLazyQuery, useRequestPortfolioCalcSnapshotCreationMutation} from "../../__generated__/generated_types";
import {Inline} from "@zendeskgarden/react-loaders";
import {ViziblyTheme} from "../../styles/zendesk-garden/ViziblyZDGTheme";
import {ThemeProvider} from "@zendeskgarden/react-theming";
import {CustomZDDropdown} from "../../atoms/custom-zd-dropdown/CustomZDDropdown";

export function PortfolioFinancialPage(): ReactElement {
    const [requestSnapshot] = useRequestPortfolioCalcSnapshotCreationMutation({
        notifyOnNetworkStatusChange: true,
    });
    const [fetchLatestSnapshot, {data: latestSnapshotData, loading: latestSnapshotLoading}] = useGetLatestPortfolioCalcSnapshotInfoLazyQuery({
        fetchPolicy: "no-cache"
    });
    const [currentSnapshot, setCurrentSnapshot] = useState<TSnapshotData>();
    const [creatingSnapshot, setCreatingSnapshot] = useState(false);
    const [secondsBeforeCanRequestSnapshot, setSecondsBeforeCanRequestSnapshot] = useState<number>();
    const [selectedPropertyIds, setSelectedPropertyIds] = useState<string[]>([]);
    const coa = useChartOfAccounts();
    const properties = useProperties();
    const budgetYear = properties.properties?.[0]?.budgetYear;

    const selectedProperties = useMemo(() => {
        if (!properties.properties) {
            return [];
        }
        return properties.properties.filter(p => selectedPropertyIds.includes(p.id));
    }, [selectedPropertyIds]);

    function pollLatestSnapshotOnce(timeout = 1000) {
        if (budgetYear === undefined) {
            return;
        }

        setTimeout(() => {
            fetchLatestSnapshot({
                variables: {
                    budgetYear: budgetYear
                }
            });
        }, timeout);
    }

    function handleRequestSnapshot() {
        if (budgetYear === undefined) {
            return;
        }

        requestSnapshot({
            variables: {
                budgetYear: budgetYear
            }
        }).then(() => {
            pollLatestSnapshotOnce();
        });
        setCreatingSnapshot(true);
    }

    function startCountdown(targetTime: number) { // targetTime in seconds
        const countdownInterval = setInterval(() => {
            const currentTime = new Date().getTime() / 1000;
            const remainingTime = targetTime - currentTime;

            if (remainingTime <= 0) {
                setSecondsBeforeCanRequestSnapshot(undefined);
                clearInterval(countdownInterval);
            }
            else {
                setSecondsBeforeCanRequestSnapshot(Math.floor(remainingTime));
            }
        }, 1000);
    }

    useEffect(() => {
        if (!budgetYear || currentSnapshot) {
            return;
        }
        setSelectedPropertyIds(properties.properties?.map(p => p.id) ?? []);
        pollLatestSnapshotOnce();
    }, [budgetYear]);

    useEffect(() => {
        if (!latestSnapshotData || latestSnapshotLoading) {
            return;
        }
        const latestSnapshot = latestSnapshotData.getLatestPortfolioCalcSnapshotInfo;
        if (!latestSnapshot) {
            if (!creatingSnapshot) {
                handleRequestSnapshot();
            }
            else {
                pollLatestSnapshotOnce();
            }
        }
        else {
            if (currentSnapshot?.snapshotId == latestSnapshot.id) {
                if (creatingSnapshot) {
                    pollLatestSnapshotOnce();
                }
            }
            else {
                const loadedSnapshotTime = new Date(latestSnapshot.createdAt);
                setCurrentSnapshot({
                    snapshotId: latestSnapshot.id,
                    snapshotTime: loadedSnapshotTime
                });
                const secondsSinceLoadedSnapshotTime = (Date.now() - loadedSnapshotTime.getTime()) / 1000;
                const secondsBeforeCanRequestSnapshot = 310 - secondsSinceLoadedSnapshotTime;
                if (secondsBeforeCanRequestSnapshot > 0) {
                    setSecondsBeforeCanRequestSnapshot(Math.floor(secondsBeforeCanRequestSnapshot));
                    startCountdown(loadedSnapshotTime.getTime() / 1000 + secondsBeforeCanRequestSnapshot + secondsSinceLoadedSnapshotTime);
                }
                else {
                    setSecondsBeforeCanRequestSnapshot(undefined);
                }
                setCreatingSnapshot(false);
            }
        }
    }, [latestSnapshotData, latestSnapshotLoading]);

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <div style={{width: "100%", height: "calc(100vh - 75px)", paddingTop: "75px"}}>
                <div style={{display: "flex", flexDirection: "row", columnGap: "0.5rem"}}>
                    <h2>Portfolio</h2>
                    {currentSnapshot ?
                        <span>as of {currentSnapshot.snapshotTime.toString()}</span>
                        :
                        ""
                    }
                    <Button onClick={handleRequestSnapshot} disabled={creatingSnapshot || !properties.properties || secondsBeforeCanRequestSnapshot !== undefined}>
                        {creatingSnapshot
                            ? <Inline size={24} aria-label="loading" />
                            : `Refresh ${secondsBeforeCanRequestSnapshot === undefined ? "" : `(${secondsBeforeCanRequestSnapshot}s)`}`
                        }
                    </Button>
                    {properties.properties &&
                        <div style={{maxWidth: "15rem", maxHeight: "100px", marginLeft: "auto", marginRight: 0}}>
                            <CustomZDDropdown
                                initialSelectedItems={selectedPropertyIds}
                                applySelectedItems={function (items: unknown): void {
                                    setSelectedPropertyIds(items as string[]);
                                }}
                                openDropdownPlaceholder={"Properties"}
                                closedDropdownPlaceholder={"Properties"}
                                options={properties.properties.map(p => ({value: p.id, label: p.name}))}
                                isError={undefined}
                                isMulti
                                allOption
                            />
                        </div>
                    }

                </div>
                {
                    coa.chartOfAccounts && properties.properties && coa.isReady && currentSnapshot ?
                        <PortfolioFinancialTable
                            snapshotId={currentSnapshot.snapshotId}
                            chartOfAccountsTree={coa.chartOfAccounts}
                            properties={selectedProperties}
                            coaFilter={undefined}
                        />
                        : <h1>No Data</h1>
                }
            </div>
        </ThemeProvider>
    );
}