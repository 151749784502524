import {useEffect, useState} from "react";
import {useGetPortfolioSnapshotAccountPerPropertyDataLazyQuery} from "../../__generated__/generated_types";

interface IAccountPropertiesDataLoader {
    snapshotId: string,
    propertyIds: string[],
}

export type TPropertiesData = Record<string, number[]>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useAccountPropetiesDataLoader(props: IAccountPropertiesDataLoader) {
    const [data, setData] = useState<Record<string, TPropertiesData>>({});
    // FIFO queue. (queue[0] is what currently loading)
    const [accountIdsQueue, setAccountIdsQueue] = useState<string[]>([]);
    const [fetchPortfolioAccountData, {data: portfolioAccountData, loading: portfolioDataAccountLoading}] = useGetPortfolioSnapshotAccountPerPropertyDataLazyQuery({
        fetchPolicy: "no-cache"
    });

    function loadDataForAccount(accountId: string): void {
        if (!accountIdsQueue.includes(accountId)) {
            setAccountIdsQueue(prev => [...prev, accountId]);
        }
    }

    function reset(): void {
        setData({});
        setAccountIdsQueue([]);
    }

    useEffect(() => {
        const accountId = accountIdsQueue[0];
        if (!accountId || portfolioDataAccountLoading) {
            return;
        }
        fetchPortfolioAccountData({
            variables: {
                snapshotId: props.snapshotId,
                propertyIds: props.propertyIds,
                accountId: accountId
            }
        });
    }, [accountIdsQueue]);

    useEffect(() => {
        if (!portfolioAccountData || portfolioDataAccountLoading) {
            return;
        }
        const accountId = portfolioAccountData.getPortfolioSnapshotAccountPerPropertyData.accountId;
        if (accountIdsQueue.includes(accountId)) {
            const updatedData = {
                ...data
            };
            const accountData: TPropertiesData = {};
            for (const propertiesValues of portfolioAccountData.getPortfolioSnapshotAccountPerPropertyData.propertiesValues) {
                accountData[propertiesValues.id] = [...propertiesValues.values];
            }
            updatedData[accountId] = accountData;
            setData(updatedData);
            setAccountIdsQueue(prev => prev.filter(id => id != accountId));
        }

    }, [portfolioAccountData, portfolioDataAccountLoading]);

    return {
        loadDataForAccount,
        data,
        reset
    };
}