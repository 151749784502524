import {FinancialEntityType} from "../../../../__generated__/generated_types";
import {FinancialEntity} from "../../../../contexts/chartofaccounts/ChartOfAccountsContext";
import {ItemData} from "./types";

type ItemDataCut = Omit<ItemData, "selected" | "collapsed" | "tags">;

export function mapFEToFilterRow(e: FinancialEntity, parentIds: string[]): ItemDataCut {
    return {
        id: e.id,
        glNumber: e.number,
        glName: e.name,
        parentIds: [...parentIds],
        type: e.type,
    };
}

export function addChildren(parent: FinancialEntity, parentIdsIn: string[], result: ItemDataCut[]): void {
    const parentIds = [...parentIdsIn];
    if (parent.type !== FinancialEntityType.Component) {
        parentIds.unshift(parent.id);
    }
    for (const child of parent.children.sortBy("order")) {
        result.push(mapFEToFilterRow(child, parentIds));
        addChildren(child, parentIds, result);
    }
}

export function buildRows(chartOfAccountsTree: FinancialEntity[]): ItemDataCut[] {
    const rows: ItemDataCut[] = [];
    for (const record of chartOfAccountsTree.sortBy("order")) {
        const parentIds = [] as string[];
        addChildren(record, parentIds, rows);
        rows.push(mapFEToFilterRow(record, []));
    }
    return rows;
}

