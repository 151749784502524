import {ReactElement, useEffect, useMemo, useState} from "react";
import {Body, Close, Footer, FooterItem, Header, Modal} from "@zendeskgarden/react-modals";
import {Field as FormField, Label, Checkbox, MediaInput} from "@zendeskgarden/react-forms";
import {Button} from "@zendeskgarden/react-buttons";
import {FixedSizeList, ListChildComponentProps} from "react-window";
import {ReactComponent as SearchIcon} from '@zendeskgarden/svg-icons/src/16/search-stroke.svg';
import {ReactComponent as ChevronDownIcon} from '@zendeskgarden/svg-icons/src/16/chevron-down-stroke.svg';
import {ReactComponent as ChevronRightIcon} from '@zendeskgarden/svg-icons/src/16/chevron-right-stroke.svg';
import {ReactComponent as MaximizeIcon} from '@zendeskgarden/svg-icons/src/16/maximize-stroke.svg';
import {ReactComponent as MinimizeIcon} from '@zendeskgarden/svg-icons/src/16/minimize-stroke.svg';
import {ReactComponent as CheckboxIcon} from '@zendeskgarden/svg-icons/src/16/check-box-stroke.svg';
import cn from "classnames";
import * as css from "./styles/accountFilter.module.scss";
import {FinancialEntityType, useQueryAccountTagsLazyQuery} from "../../../../__generated__/generated_types";
import {TagSelector} from "../../../coa-tags/components/TagSelector";
import {ItemData} from "./types";
import {FinancialEntity} from "../../../../contexts/chartofaccounts/ChartOfAccountsContext";
import {buildRows} from "./logic";

export type TAccountDriverUpdate = {
    accountId: string;
    glName: string;
    glNumber: string;
};

export type TAccountDriver = {
    accountId: string;
    glName: string;
    glNumber: string;
};

export type AccountFilterProps = {
    onClose: () => void;
    onConfirm: (updatedSelectedAccountIds: string[]) => void;

    selectedAccountIds: string[];
    chartOfAccountsTree: FinancialEntity[];
};

export function itemText(item: ItemData): string {
    return `${item.glNumber ? (item.glNumber + " - ") : ""} ${item.glName}`;
}

type ItemProps = {
    item: ItemData,
    allItems: ItemData[],
    allTags: string[],
    style?: React.CSSProperties,
    handleCollapse?: (item: ItemData, collapsed: boolean) => void,
    handleSelect: (item: ItemData, selected: boolean) => void,
};

function Item({
    item,
    style,
    handleCollapse,
    handleSelect,
    allItems
}: ItemProps): ReactElement {
    const [indeterminate, checked] = useMemo(
        () => {
            let indeterminate = false;
            let checked = false;
            const children = allItems.filter(it => it.type == "ACCOUNT" && it.parentIds.includes(item.id));
            if (children.length > 0) {
                const selectedChildrenCount = children.count(child => child.selected);
                indeterminate = selectedChildrenCount > 0 && children.length > selectedChildrenCount;
                checked = selectedChildrenCount == children.length;
            }
            else {
                checked = item.selected;
            }
            return [indeterminate, checked];
        },
        [allItems]
    );

    return (
        <div
            style={style}
            className={cn(
                css.item,
                item.type == FinancialEntityType.Account ? css.plain : undefined,
            )}
        >
            <FormField>
                <Checkbox
                    className={css.checkbox}
                    checked={checked}
                    onChange={() => {
                        handleSelect(item, !checked);
                    }}
                    indeterminate={indeterminate}
                >
                    <Label className={css.checkbox} />
                </Checkbox>
            </FormField>
            <div
                className={cn(
                    css.content,
                )}
                style={{paddingLeft: `${item.parentIds.length * 48}px`}}
            >
                <div
                    className={css.chevron}
                >
                    {item.type != FinancialEntityType.Account &&
                        <Button
                            isBasic
                            onClick={() => handleCollapse?.(item, !item.collapsed)}
                        >
                            {item.collapsed ? <ChevronRightIcon /> : <ChevronDownIcon />}
                        </Button>
                    }
                </div>
                <div className={css.accountName}>
                    {item.glNumber ? `${item.glNumber} - ` : ""}{item.glName}
                </div>
            </div>
        </div>
    );
}

export function AccountFilter(props: AccountFilterProps): ReactElement {
    const [queryAccountTags, {data: accountTagsData, loading: accountTagsLoading}] = useQueryAccountTagsLazyQuery({
        fetchPolicy: "no-cache"
    });

    const [items, setItems] = useState<ItemData[]>([]);
    const [displayItems, setDisplayItems] = useState<ItemData[]>([]);
    const [visibleItems, setVisibleItems] = useState<ItemData[]>([]);
    const [searchString, setSearchString] = useState<string>();
    const [searchTags, setSearchTags] = useState<string[]>([]);
    const [searchByTagsVisible, setSearchByTagsVisible] = useState(false);
    const [allTags, setAllTags] = useState<string[]>([]);
    function updateDisplayItems(
        sourceItems: ItemData[],
        searchString: string | undefined,
        searchTags: string[]
    ) {
        let updated = [...sourceItems];
        if (searchString || searchTags.length > 0) {
            let temp = updated;
            if (searchString && searchString.length > 0) {
                temp = temp.filter(item => itemText(item).toLowerCase().includes(searchString.toLowerCase()));
            }
            if (searchTags.length > 0) {
                temp = temp.filter(item => item.tags.length > 0 && item.tags.find(tag => searchTags.find(t => t == tag)));
            }
            const ids = new Set(temp.map(item => item.id));
            const parentIds = new Set(temp.flatMap(item => item.parentIds).dedupe());
            updated = updated.filter(item =>
                ids.has(item.id)
                // I want to show user the path to the top level from the item filtered
                || parentIds.has(item.id)
            );
        }
        setVisibleItems(updated); // important to set visible items before hiding with collapsing
        const collapsedIds = updated.filter(item => item.collapsed).map(item => item.id);
        updated = updated.filter(item => !item.parentIds.some(id => collapsedIds.includes(id)));
        setDisplayItems(updated);
    }


    function handleCollapse(item: ItemData, collapsed: boolean) {
        const updated = [...items];
        const foundItem = updated.find(it => it.id == item.id);
        if (foundItem) {
            foundItem.collapsed = collapsed;
        }
        updateDisplayItems(updated, searchString, searchTags);
    }

    function handleSelect(item: ItemData, selected: boolean) {
        const updated = [...items];
        const visibleItemIds = new Set(visibleItems.map(it => it.id));
        for (const it of updated) {
            if (it.id == item.id || it.parentIds.includes(item.id) && visibleItemIds.has(it.id)) {
                it.selected = selected;
            }
        }
        updateDisplayItems(updated, searchString, searchTags);
    }

    function handleSelectAll(selected: boolean) {
        const updated = [...items];
        const visibleItemIds = new Set(visibleItems.map(it => it.id));
        for (const it of updated) {
            if (visibleItemIds.has(it.id)) {
                it.selected = selected;
            }
        }
        updateDisplayItems(updated, searchString, searchTags);
    }

    function handleCollapseAll(collapsed: boolean) {
        const visibleItemIds = new Set(visibleItems.map(it => it.id));
        for (const item of items) {
            if (item.type != FinancialEntityType.Account && visibleItemIds.has(item.id)) {
                item.collapsed = collapsed;
            }
        }
        updateDisplayItems(items, searchString, searchTags);
    }

    function handleConfirm() {
        let update = [];
        for (const it of items) {
            if (!it.selected || !(it.type === FinancialEntityType.Account || it.type === FinancialEntityType.Component)) {
                continue;
            }
            update.push(it.id, ...it.parentIds);
        }
        update = update.dedupe();
        props.onConfirm(update);
    }

    function COARow(rowProps: ListChildComponentProps<ItemData[]>) {
        const {data: displayItems, index, style} = rowProps;
        const item = displayItems[index];
        if (!item) return null;
        return (
            <Item
                item={item}
                allItems={visibleItems}
                allTags={allTags}
                handleCollapse={(item: ItemData, collapsed: boolean) => handleCollapse(item, collapsed)}
                handleSelect={(item: ItemData, selected: boolean) => handleSelect(item, selected)}
                style={style}
            />
        );
    }

    useEffect(
        () => {
            const initialItems: ItemData[] = [];
            const coaFlatMapped = buildRows(props.chartOfAccountsTree);
            for (const row of coaFlatMapped) {
                initialItems.push({
                    id: row.id,
                    glNumber: row.glNumber,
                    glName: row.glName,
                    parentIds: row.parentIds.copy(),
                    type: row.type,

                    collapsed: false,
                    selected: props.selectedAccountIds.includes(row.id),
                    tags: [],
                });
            }
            setItems(initialItems);
            updateDisplayItems(initialItems, searchString, searchTags);
            queryAccountTags(); // I want to load tags after was coa loaded
        },
        []
    );

    useEffect(() => {
        if (accountTagsData && !accountTagsLoading) {
            const allTags = new Set<string>();
            const selectionTags = new Set<string>();
            const tagsByAccountId = accountTagsData.queryAccountTags.toIdMap("accountId", "tags");
            const updated = [...items];
            for (const item of updated) {
                if (item.type == "ACCOUNT") {
                    item.tags = tagsByAccountId[item.id] ?? [];
                    for (const tag of item.tags) {
                        allTags.add(tag);
                        if (item.selected) {
                            selectionTags.add(tag);
                        }
                    }
                }
            }
            updateDisplayItems(updated, searchString, searchTags);
            setAllTags(Array.from(allTags));
            setSearchTags(prev => prev.filter(tag => allTags.has(tag)));
        }
    }, [accountTagsData, accountTagsLoading]);

    useEffect(
        () => {
            updateDisplayItems(items, searchString, searchTags);
        },
        [searchString, searchTags]
    );

    return (
        <Modal onClose={() => props.onClose()} isLarge style={{overflow: "unset"}} className={css.modal}>
            <Header>
                Filter Accounts
            </Header>
            <Body>
                <div className={css.listHeader}>
                    <div className={css.listHeaderContainer}>
                        <div className={css.listHeaderButtonContainer}>
                            <Button className={css.headerButton}
                                isBasic
                                onClick={() => handleCollapseAll(true)}
                            >
                                <MinimizeIcon />
                            </Button>
                            <Button className={css.headerButton}
                                isBasic
                                onClick={() => handleCollapseAll(false)}
                            >
                                <MaximizeIcon />
                            </Button>
                            <Button className={css.headerButton}
                                isBasic
                                onClick={() => handleSelectAll(true)}
                            >
                                <CheckboxIcon />&nbsp;All
                            </Button>
                            <Button className={css.headerButton}
                                isBasic
                                onClick={() => handleSelectAll(false)}
                            >
                                <CheckboxIcon />&nbsp;None
                            </Button>
                        </div>
                        <div className={css.listHeaderInputBoxContainer}>
                            <FormField className={css.inputBox}>
                                <MediaInput start={<SearchIcon />} value={searchString} placeholder="Search" onChange={e => setSearchString(e.target.value)} />
                            </FormField>
                            <FormField className={css.inputBox}>
                                <MediaInput
                                    start={<SearchIcon />}
                                    onClick={() => setSearchByTagsVisible(true)}
                                    value={searchTags.length > 0 ? searchTags.join(", ") : ""}
                                    placeholder="Filter By Tags"
                                />
                                {searchByTagsVisible &&
                                    <TagSelector
                                        title="Filter By Tags"
                                        canAdd={false}
                                        options={allTags.map(t => ({label: t, value: t}))}
                                        selected={searchTags.map(t => ({label: t, value: t}))}
                                        onConfirm={(tags) => {setSearchTags(tags.map(t => t.value)); setSearchByTagsVisible(false);}}
                                        onClose={() => setSearchByTagsVisible(false)}
                                    />
                                }
                            </FormField>
                        </div>
                    </div>
                </div>
                <FixedSizeList<ItemData[]>
                    height={430}
                    itemCount={displayItems.length}
                    itemSize={40}
                    width={"100%"}
                    itemData={displayItems}
                >
                    {COARow}
                </FixedSizeList>
            </Body>
            <Footer>
                <FooterItem>
                    <Button onClick={() => props.onClose()} isBasic disabled={false}>
                        Cancel
                    </Button>
                </FooterItem>
                <FooterItem>
                    {
                        <Button isPrimary onClick={() => handleConfirm()}>
                            Apply
                        </Button>
                    }
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>

    );
}